import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import IUser from '@constants/interfaces/user';
import { appLinks } from '../constants';
import { loginApi } from 'src/services/api';
import { WebPrimaryButton } from '../webButtons';
import { WebLink } from '../webLink';

const WebLoginPage: React.FC<{
  setCurrentUser: React.Dispatch<React.SetStateAction<string>>;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setCurrentUser, setIsLoggedIn }) => {
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-lg">
        <img
          className="mx-auto"
          src="icons/icon128.png"
          alt="login icon"
          width={56}
          height={56}
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full max-w-full sm:max-w-lg">
        <Formik
          enableReinitialize={true}
          initialValues={{} as IUser}
          onSubmit={async (values) => {
            const res = await loginApi(values);
            if (res !== undefined) {
              const isSuccess = res.ok;
              const response = await res.json();

              if (isSuccess) {
                const userData = response.data;
                setCurrentUser(userData.UserEmail);
                delete userData.UserEmail;
                localStorage.setItem(
                  'flower_power_notes_user',
                  JSON.stringify(userData)
                );
                setIsLoggedIn(true);
                navigate(appLinks.MAIN_PAGE);
              } else {
                setLoginError(true);
              }
            }
          }}
        >
          {({ values }) => (
            <Form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email*
                </label>

                <Field
                  type="email"
                  value={values.email || ''}
                  name="email"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password*
                  </label>
                </div>
                <Field
                  type="password"
                  value={values.password || ''}
                  name="password"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />

                {loginError ? (
                  <p className="text-red-500 font-bold pt-[10px]">
                    Invalid login data!
                  </p>
                ) : null}
              </div>
              <div>
                <WebPrimaryButton isSubmit={true} isWidthFull={true}>
                  Log in
                </WebPrimaryButton>
              </div>
              <p className="text-center">
                Don't have an account?{' '}
                <WebLink to={appLinks.SIGN_UP}>Create</WebLink>
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default WebLoginPage;
