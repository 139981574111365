import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { WithContext as ReactTags } from 'react-tag-input';

import IContact from '@constants/interfaces/contacts';
import { appLinks } from './constants';
import WebDeleteModal from './WebDeleteModal';
import { addNewContact } from 'src/services/api';
import WebTitle from './WebTitle';
import { WebPrimaryButton, WebSecondaryButton } from './webButtons';
import { NavigateToLastPage } from './webLink';

interface ITag {
  id: string;
  text: string;
}

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const AddEditContact: React.FC<{
  contactSelected?: IContact | undefined;
  title: string;
}> = ({ contactSelected, title }) => {
  const [tags, setTags] = useState<Array<ITag>>([]);
  const [formError, setFormError] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const focusElement = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    (focusElement?.current?.childNodes[1] as HTMLInputElement)?.focus();
    const tagsIntoListOfObjects = (contactSelected?.Tags || []).map(
      (tag: string) => ({
        id: tag,
        text: tag,
      })
    );
    setTags(tagsIntoListOfObjects);
  }, [contactSelected]);

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleDelete = (i: any) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag: any) => {
    setTags([...tags, tag]);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 flex-1 pt-3 pb-10">
      <WebDeleteModal
        contactSelected={contactSelected as IContact}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
      />
      <div className="max-w-[500px] mx-auto">
        <WebTitle title={title} />
        <Formik
          enableReinitialize={true}
          initialValues={contactSelected || ({} as IContact)}
          onSubmit={async (values) => {
            const loggedInUser = JSON.parse(
              localStorage.getItem('flower_power_notes_user') || '{}'
            );
            values.UserId = loggedInUser.UserId;
            const valueTags = tags.map((el: ITag) => el.text);
            values.Tags = valueTags;
            const isSuccess = await addNewContact(values);
            if (isSuccess) {
              navigate(appLinks.MAIN_PAGE);
            } else setFormError(true);
          }}
        >
          {({ values }) => (
            <Form>
              <div className="mt-5" ref={focusElement}>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Name* :
                </label>
                <Field
                  type="text"
                  value={values?.Name || ''}
                  name="Name"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mt-5">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Email:
                </label>

                <Field
                  type="email"
                  value={values?.Email || ''}
                  name="Email"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mt-5">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Profile URL* :
                </label>
                <Field
                  type="text"
                  required
                  value={values?.ProfileUrl || ''}
                  name="ProfileUrl"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mt-5">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Where we met:
                </label>

                <Field
                  type="text"
                  value={values?.WhereWeMet || ''}
                  name="WhereWeMet"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mt-5">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Context:
                </label>

                <Field
                  className="textarea-scroll resize-none shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  component="textarea"
                  rows="5"
                  cols="60"
                  name="Context"
                  value={values?.Context || ''}
                  maxLength="2000"
                />
              </div>
              <div className="mt-5">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Notes:
                </label>

                <Field
                  className="textarea-scroll resize-none shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  component="textarea"
                  rows="5"
                  cols="60"
                  name="Notes"
                  value={values?.Notes || ''}
                  maxLength="2000"
                />
              </div>
              <div className="mt-5">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Tags:
                </label>
                <ReactTags
                  tags={tags}
                  //we can use suggestions when we make tag model
                  // suggestions={suggestions}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  inputFieldPosition="top"
                  allowDragDrop={false}
                  classNames={{
                    selected: 'flex flex-wrap my-[5px]',
                    remove: 'ml-[5px] font-black text-gray-500',
                    tag: 'shadow py-[2px] px-[7px] mb-[5px] ml-[5px] text-gray-700 focus:outline-none rounded focus:shadow-outline',
                    tagInputField:
                      'shadow appearance-none border rounded w-full py-[10px] px-[15px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
                  }}
                />
              </div>
              {formError ? (
                <p className="text-red-500 font-bold pt-[10px]">
                  Error happened, contact can't be saved!
                </p>
              ) : null}
              <div className="flex justify-around items-center mt-5">
                <NavigateToLastPage>Cancel</NavigateToLastPage>
                {contactSelected?.Name && (
                  <WebSecondaryButton onClick={() => setModalIsOpen(true)}>
                    Delete
                  </WebSecondaryButton>
                )}
                <WebPrimaryButton isSubmit={true}>Save</WebPrimaryButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddEditContact;
