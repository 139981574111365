import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

interface ButtonProps {
  children: React.ReactNode;
  to: string;
}

export const PrimaryLink: React.FC<ButtonProps> = ({ children, to }) => {
  return (
    <Link
      to={to}
      type="button"
      className="py-1 text-center px-4 rounded-lg bg-sky-600 hover:bg-gradient-to-r hover:from-sky-700 hover:to-sky-600 text-white font-bold drop-shadow hover:drop-shadow-xl"
    >
      {children}
    </Link>
  );
};

export const SecondaryLink: React.FC<ButtonProps> = ({ children, to }) => {
  return (
    <Link
      to={to}
      type="button"
      className="min-w-[80px] text-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    >
      {children}
    </Link>
  );
};

export const WebLink: React.FC<ButtonProps & { isNoramlText?: boolean }> = ({
  children,
  to,
  isNoramlText,
}) => {
  return (
    <Link
      to={to}
      type="button"
      className={`${isNoramlText ? '' : 'text-blue-600 font-semibold '}`}
    >
      {children}
    </Link>
  );
};

export const NavigateToLastPage: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      onClick={() => navigate(-1)}
      className="min-w-[80px] text-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    >
      {children}
    </button>
  );
};
