import React from 'react';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  isDanger?: boolean;
  isSubmit?: boolean;
  isWidthFull?: boolean;
}

export const WebPrimaryButton: React.FC<ButtonProps> = ({
  children,
  isSubmit,
  isWidthFull,
}) => {
  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      className={`min-w-[50px] text-center bg-sky-600 hover:bg-sky-500 focus-visible:outline-sky-600 rounded px-2 py-1 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
        isWidthFull && 'w-full'
      }`}
    >
      {children}
    </button>
  );
};

export const WebSecondaryButton: React.FC<ButtonProps> = ({
  children,
  onClick,
  isDanger,
  isSubmit,
}) => {
  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      onClick={onClick}
      className={`min-w-[50px] text-center rounded px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  ${
        isDanger
          ? 'bg-red-700 hover:bg-red-600 text-white'
          : 'bg-white hover:bg-gray-50'
      }`}
    >
      {children}
    </button>
  );
};
