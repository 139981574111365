import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { render } from 'react-dom';
import './index.css';
import App from './pages/Web/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const root = document.getElementById('root') as HTMLElement;

render(
  // StrictMode renders components twice (on dev but not production) in order to detect
  // any problems with your code and warn you about them (which can be quite useful).
  <Router>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Router>,
  root
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
