import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Header } from 'src/components/web/Header';
import IContact from '@constants/interfaces/contacts';
import WebMainPage from 'src/components/web/pages/WebMainPage';
import WebLoginPage from 'src/components/web/pages/WebLoginPage';
import { IRouteElement, appLinks } from 'src/components/web/constants';
import WebAddContact from 'src/components/web/pages/WebAddContact';
import WebEditContact from 'src/components/web/pages/WebEditContact';
import WebSignUp from 'src/components/web/pages/WebSignUp';
import WebDetails from 'src/components/web/pages/WebDetails';
import { getUserEmail } from 'src/services/api';
import ProtectedRoute from 'src/components/web/ProtectedRoute';

const App = () => {
  const [currentUser, setCurrentUser] = useState<string>('');
  const [contactSelected, setContactSelected] = useState<IContact>();
  // prettier-ignore
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => localStorage.getItem('flower_power_notes_user') !== null);

  useEffect(() => {
    const setup = async () => {
      const loggedInUserId = JSON.parse(
        localStorage.getItem('flower_power_notes_user') || '{}'
      )?.UserId;
      if (loggedInUserId) {
        const userEmail = await getUserEmail(loggedInUserId);
        setCurrentUser(userEmail);
      }
    };

    setup();
  }, []);

  const routeElements = [
    {
      component: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <WebMainPage setContactSelected={setContactSelected} />
        </ProtectedRoute>
      ),
      link: appLinks.MAIN_PAGE,
    },
    { component: <WebSignUp />, link: appLinks.SIGN_UP },
    {
      component: (
        <WebLoginPage
          setCurrentUser={setCurrentUser}
          setIsLoggedIn={setIsLoggedIn}
        />
      ),
      link: appLinks.LOGIN,
    },
    {
      component: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <WebAddContact />
        </ProtectedRoute>
      ),
      link: appLinks.ADD,
    },
    {
      component: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <WebEditContact contactSelected={contactSelected} />
        </ProtectedRoute>
      ),
      link: appLinks.EDIT,
    },
    {
      component: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <WebDetails contactSelected={contactSelected} />
        </ProtectedRoute>
      ),
      link: appLinks.DETAILS,
    },
  ];

  return (
    <main className="w-full h-screen">
      {currentUser && (
        <Header
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          setIsLoggedIn={setIsLoggedIn}
        />
      )}
      <Routes>
        {routeElements.map((routeElement: IRouteElement, index: number) => (
          <Route
            path={routeElement.link}
            element={routeElement.component}
            key={index}
          />
        ))}
      </Routes>
    </main>
  );
};

export default App;
