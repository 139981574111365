import React from 'react';
import ContactDetailDisplay from '../ContactDetailDisplay';
import { appLinks, contactFields } from '../constants';
import IContact from '@constants/interfaces/contacts';
import WebTitle from '../WebTitle';
import { SecondaryLink } from '../webLink';

const WebDetails: React.FC<{ contactSelected: IContact | undefined }> = ({
  contactSelected,
}) => {
  return (
    <div className="flex flex-col px-4 sm:px-6 lg:px-8 flex-1 pt-3">
      <div className="max-w-[1000px] mx-auto flex flex-col">
        <WebTitle title="Contact Details" />
        {contactSelected?.Name ? (
          <div className="flex items-center flex-col justify-center mt-2">
            <table className="min-w-full divide-y divide-gray-300">
              <tbody className="divide-y divide-gray-200">
                <ContactDetailDisplay
                  fieldName={contactFields.NAME}
                  fieldVariable={`${contactSelected?.Name || ''}`}
                />
                <ContactDetailDisplay
                  fieldName={contactFields.EMAIL}
                  fieldVariable={`${contactSelected?.Email || ''}`}
                />
                <ContactDetailDisplay
                  fieldName={contactFields.PROFILE_URL}
                  fieldVariable={`${contactSelected?.ProfileUrl || ''}`}
                />
                <ContactDetailDisplay
                  fieldName={contactFields.WHERE_WE_MET}
                  fieldVariable={`${contactSelected?.WhereWeMet || ''}`}
                />
                <ContactDetailDisplay
                  fieldName={contactFields.CONTEXT}
                  fieldVariable={`${contactSelected?.Context || ''}`}
                />
                <ContactDetailDisplay
                  fieldName={contactFields.NOTES}
                  fieldVariable={`${contactSelected?.Notes || ''}`}
                />
                <ContactDetailDisplay
                  fieldName={contactFields.TAGS}
                  fieldVariable={contactSelected?.Tags || []}
                />
              </tbody>
            </table>
          </div>
        ) : (
          <div className="mt-10 font-semibold text-3xl">
            No contact selected
          </div>
        )}
        <div className="mt-5 flex justify-around">
          <SecondaryLink to={appLinks.MAIN_PAGE}>Cancel</SecondaryLink>
          {contactSelected?.Name && (
            <SecondaryLink to={appLinks.EDIT}>Edit</SecondaryLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default WebDetails;
