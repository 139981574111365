import React, { useState } from 'react';

const ReadMore = ({ children }: { children: string }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const shouldShow = text?.length > 150;
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const renderText = () => {
    if (isReadMore) return ' ...see more';
    return ' show less';
  };

  return (
    <p className="m-0 text-[14px]">
      {isReadMore ? text.slice(0, 150) : text}
      <span onClick={toggleReadMore} className=" cursor-pointer text-cyan-500">
        {shouldShow && renderText()}
      </span>
    </p>
  );
};

export default ReadMore;
