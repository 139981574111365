import React from 'react';
import Modal from 'react-modal';
import IContact from '@constants/interfaces/contacts';
import { useNavigate } from 'react-router-dom';
import { appLinks } from './constants';
import { deleteContactApi } from 'src/services/api';
import { WebSecondaryButton } from './webButtons';

const WebDeleteModal: React.FC<{
  contactSelected: IContact;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ contactSelected, isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const closeModal = () => {
    setIsOpen(false);
  };

  const deleteContact = async () => {
    await deleteContactApi(contactSelected);
    navigate(appLinks.MAIN_PAGE);
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      ariaHideApp={false}
      overlayClassName="fixed top-0 right-0 left-0 bottom-0 bg-black bg-opacity-60 z-[1010]"
      className="absolute top-1/2 left-1/2 right-auto bottom-auto mr-[-50%] translate-y-[-50%] translate-x-[-50%] bg-white border-[1px] border-[#e5e7eb] p-3 rounded-[5px] outline-none w-10/12 sm:w-[500px]"
    >
      <div className="flex justify-end">
        <span
          onClick={() => closeModal()}
          className="cursor-pointer text-gray-400 hover:text-gray-600"
        >
          ✕
        </span>
      </div>

      <div className="text-center text-base">
        Are you sure you want to delete
        <br />
        <span className="font-medium">{contactSelected?.Name}</span>
        <br />
        from your contact list?
      </div>
      <div className="flex items-center justify-around mt-5">
        <WebSecondaryButton onClick={() => setIsOpen(false)}>
          No
        </WebSecondaryButton>
        <WebSecondaryButton onClick={deleteContact} isDanger={true}>
          Yes
        </WebSecondaryButton>
      </div>
    </Modal>
  );
};

export default WebDeleteModal;
