import React from 'react';

const WebTitle: React.FC<{ title: string }> = ({ title }) => {
  return (
    <div className="flex items-center justify-center">
      <div className="text-2xl sm:text-3xl font-semibold">{title}</div>
    </div>
  );
};

export default WebTitle;
