import React, { useEffect, useState } from 'react';
import { PencilIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import IContact from '@constants/interfaces/contacts';
import { LinkedInIcon, SearchIcon } from '../../../icons/icons';
import { appLinks } from '../constants';
import WebLoading from '../WebLoading';
import { getContactList } from 'src/services/api';
import { PrimaryLink, WebLink } from '../webLink';

interface MainPageProps {
  setContactSelected: React.Dispatch<
    React.SetStateAction<IContact | undefined>
  >;
}

const WebMainPage: React.FC<MainPageProps> = ({ setContactSelected }) => {
  const [isLoading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [contactList, setContactList] = useState<Array<IContact>>([]);
  const navigate = useNavigate();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setSearchValue(e.target.value);
  };

  const contactForEdit = (contact: IContact) => {
    setContactSelected(contact);
    navigate(appLinks.EDIT);
  };

  useEffect(() => {
    const setup = async () => {
      const loggedInUserId = JSON.parse(
        localStorage.getItem('flower_power_notes_user') || '{}'
      ).UserId;
      if (loggedInUserId) {
        const contacts = await getContactList(loggedInUserId);
        setContactList(contacts);
      }
      setLoading(false);
    };

    setup();
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 flex-1 pt-3">
      {isLoading ? (
        <WebLoading isLoading={isLoading} />
      ) : (
        <div className="max-w-[1000px] mx-auto">
          <div className="flex justify-center flex-col-reverse sm:flex-row items-center">
            <div className="sm:flex-auto flex w-full">
              <div className=" border-2 border-r-0 rounded-l-lg h-[30px] w-[30px] bg-white flex items-center justify-centerrounded-l-lg pl-3 bg-clip-padding bg-opacity-90">
                <SearchIcon />
              </div>
              <input
                className="border-2 border-l-0 h-[30px] w-full sm:w-[280px] pl-3 outline-none rounded-r-lg bg-white bg-clip-padding bg-opacity-90"
                value={searchValue}
                onChange={handleChange}
                placeholder="Search..."
              />
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none mb-4 sm:mb-0">
              <PrimaryLink to={appLinks.ADD}>Add contact</PrimaryLink>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden lg:block"
                      >
                        Tags
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/12"
                      >
                        Link
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {contactList
                      .filter(
                        (contact: IContact) =>
                          contact.Name?.toLowerCase().includes(
                            searchValue.toLowerCase()
                          ) ||
                          contact.Tags?.some((tag: string) =>
                            tag
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())
                          )
                      )
                      .map((contact: IContact) => (
                        <tr key={contact.Id}>
                          <td
                            className="truncate max-w-[100px] sm:max-w-[250px] py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                            onClick={() => setContactSelected(contact)}
                          >
                            <WebLink isNoramlText={true} to={appLinks.DETAILS}>
                              {contact.Name}
                            </WebLink>
                          </td>
                          <td className="truncate max-w-[70px] sm:max-w-[200px] text px-3 py-4 text-sm text-gray-500">
                            {contact.Email}
                          </td>
                          <td className="truncate max-w-[500px] px-3 py-4 text-sm text-gray-500 hidden lg:block">
                            {contact.Tags?.map((tag: string, index: number) => (
                              <span key={index} className="shadow p-1 mr-[8px]">
                                {tag}
                              </span>
                            ))}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 flex-1">
                            <div className="flex items-center justify-center">
                              <a
                                href={contact.ProfileUrl}
                                target="_blank"
                                aria-label={`linkedId-${contact.Name}`}
                                className="text-indigo-600 hover:text-indigo-900 mr-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                              >
                                <LinkedInIcon />
                              </a>
                              <div
                                className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 pl-3 cursor-pointer"
                                onClick={() => contactForEdit(contact)}
                              >
                                <PencilIcon className="h-[17px] w-[17px] text-black stroke-2" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WebMainPage;
