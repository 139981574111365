import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import IUser from '@constants/interfaces/user';
import { appLinks } from '../constants';
import { registerApi } from 'src/services/api';
import { WebPrimaryButton } from '../webButtons';
import { WebLink } from '../webLink';

const WebSignUp = () => {
  const [registerError, setRegisterError] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-lg">
        <img
          className="mx-auto"
          src="icons/icon128.png"
          alt="sign in icon"
          width={56}
          height={56}
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign up
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full max-w-full sm:max-w-lg">
        <Formik
          enableReinitialize={true}
          initialValues={{} as IUser}
          onSubmit={async (values) => {
            const res = await registerApi(values);
            if (res !== undefined) {
              const isSuccess = res.ok;

              if (isSuccess) {
                navigate(appLinks.LOGIN);
              } else {
                setRegisterError(true);
              }
            }
          }}
        >
          {({ values }) => (
            <Form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email*
                </label>

                <Field
                  type="email"
                  value={values.email || ''}
                  name="email"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password*
                  </label>
                </div>
                <Field
                  type="password"
                  value={values.password || ''}
                  name="password"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />

                {registerError ? (
                  <p className="text-red-500 font-bold pt-[10px]">
                    Email already exists
                  </p>
                ) : null}
              </div>
              <div>
                <WebPrimaryButton isSubmit={true} isWidthFull={true}>
                  Sign up
                </WebPrimaryButton>
              </div>
              <p className="text-center">
                Already have an account?{' '}
                <WebLink to={appLinks.LOGIN}>Log in</WebLink>
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default WebSignUp;
