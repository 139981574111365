import React from 'react';
import ReadMore from '../popup/common/readMore';
import { contactFields } from './constants';

interface ContactDetailDisplayProps {
  fieldName: string;
  fieldVariable: string | Array<string>;
}

const ContactDetailDisplay: React.FC<ContactDetailDisplayProps> = ({
  fieldName,
  fieldVariable,
}) => {
  const renderFieldVariable = () => {
    if (fieldVariable)
      if (
        fieldName === contactFields.PROFILE_URL &&
        typeof fieldVariable == 'string'
      )
        return (
          <a href={fieldVariable} target="_blank" className="text-blue-600">
            View profile
          </a>
        );
      else if (fieldName === contactFields.TAGS && Array.isArray(fieldVariable))
        return (
          <div className="text-[14px] flex flex-wrap my-2">
            {fieldVariable.map((tag: string, index: number) => (
              <div
                key={index}
                className="shadow py-1 px-2 text-gray-700 mr-[10px] mt-[5px] focus:outline-none rounded focus:shadow-outline"
              >
                {tag}
              </div>
            ))}
          </div>
        );
      else if (typeof fieldVariable == 'string')
        return <ReadMore>{fieldVariable}</ReadMore>;
    return <label className="text-xs italic text-gray-400">-</label>;
  };

  return (
    <tr>
      <td className="px-3 py-4 text-sm text-gray-500">{fieldName}</td>
      <td className="px-3 py-4 text-sm text-gray-500 min-w-[200px]">
        {renderFieldVariable()}
      </td>
    </tr>
  );
};

export default ContactDetailDisplay;
