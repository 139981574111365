import IContact from '@constants/interfaces/contacts';
import React from 'react';
import AddEditContact from '../AddEditContact';
import { appLinks } from '../constants';
import { PrimaryLink } from '../webLink';

const WebEditContact: React.FC<{ contactSelected: IContact | undefined }> = ({
  contactSelected,
}) => {
  return (
    <>
      {contactSelected?.Name ? (
        <AddEditContact
          contactSelected={contactSelected}
          title={'Edit contact'}
        />
      ) : (
        <div className="flex flex-col px-4 sm:px-6 lg:px-8 flex-1 pt-3">
          <div className="max-w-[1000px] mx-auto flex flex-col">
            <div className="mt-10 font-semibold text-3xl">
              No contact selected
            </div>

            <div className="mt-5 flex justify-center">
              <PrimaryLink to={appLinks.MAIN_PAGE}>Back</PrimaryLink>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WebEditContact;
