export enum appLinks {
  MAIN_PAGE = '/',
  LOGIN = '/login',
  SIGN_UP = '/sign-up',
  LOG_OUT = '/log-out',
  DETAILS = '/details',
  ADD = '/add',
  EDIT = '/edit',
}

export interface IRouteElement {
  link: string;
  component: JSX.Element;
}

export enum contactFields {
  NAME = 'Name',
  EMAIL = 'Email',
  PROFILE_URL = 'URL',
  WHERE_WE_MET = 'Met',
  CONTEXT = 'Context',
  NOTES = 'Notes',
  TAGS = 'Tags',
}
