import IContact from '@constants/interfaces/contacts';

const baseURL = process.env.REACT_APP_API_PORT || 'http://localhost:8000/';

export const loginApi = async (values: any) => {
  try {
    const res = await fetch(baseURL + 'v1/login', {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const registerApi = async (values: any) => {
  try {
    const res = await fetch(baseURL + 'v1/user', {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getUserEmail = async (loggedInUserId: string) => {
  try {
    const res = await fetch(baseURL + `v1/contact/user/${loggedInUserId}`, {
      method: 'GET',
    });
    const data = await res.json();
    return data.user;
  } catch (error) {
    console.error(error);
  }
};

export const addNewContact = async (values: any) => {
  try {
    const res = await fetch(baseURL + 'v1/contact', {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const isSuccess = await res.ok;
    return isSuccess;
  } catch (error) {
    console.error(error);
  }
};

export const deleteContactApi = async (contactSelected: IContact) => {
  try {
    const res = await fetch(baseURL + 'v1/contact', {
      method: 'DELETE',
      body: JSON.stringify(contactSelected),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const isSuccess = await res.ok;
    return isSuccess;
  } catch (error) {
    console.error(error);
  }
};

export const getContactList = async (loggedInUserId: string) => {
  try {
    const res = await fetch(baseURL + `v1/contact/all/${loggedInUserId}`, {
      method: 'GET',
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
};
