import React from 'react';
import GridLoader from 'react-spinners/GridLoader';

const WebLoading: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  return (
    <div className="spinner flex items-center justify-center h-[calc(100vh-80px)] sm:h-[calc(100vh-100px)]">
      <GridLoader color={'#066ca6'} loading={isLoading} size={30} />
    </div>
  );
};

export default WebLoading;
