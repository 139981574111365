import React from 'react';
import { Popover } from '@headlessui/react';
import { appLinks } from './constants';
import { WebLink } from './webLink';
import { WebSecondaryButton } from './webButtons';
import { useNavigate } from 'react-router-dom';

const MobileNavLink = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => {
  return (
    <Popover.Button className="block w-full p-2">
      <a href={href}>{children}</a>
    </Popover.Button>
  );
};

const MobileNavIcon = ({ open }: { open: boolean }) => {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={` 'origin-center transition',
          ${open && 'scale-90 opacity-0'}`}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={`'origin-center transition',
          ${!open && 'scale-90 opacity-0'}`}
      />
    </svg>
  );
};

// const MobileNavigation = () => {
//   return (
//     <Popover>
//       <Popover.Button
//         className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
//         aria-label="Toggle Navigation"
//       >
//         {({ open }) => <MobileNavIcon open={open} />}
//       </Popover.Button>
//       <Transition.Root>
//         <Transition.Child
//           as={Fragment}
//           enter="duration-150 ease-out"
//           enterFrom="opacity-0"
//           enterTo="opacity-100"
//           leave="duration-150 ease-in"
//           leaveFrom="opacity-100"
//           leaveTo="opacity-0"
//         >
//           <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
//         </Transition.Child>
//         <Transition.Child
//           as={Fragment}
//           enter="duration-150 ease-out"
//           enterFrom="opacity-0 scale-95"
//           enterTo="opacity-100 scale-100"
//           leave="duration-100 ease-in"
//           leaveFrom="opacity-100 scale-100"
//           leaveTo="opacity-0 scale-95"
//         >
//           <Popover.Panel
//             as="div"
//             className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
//           >
//             <MobileNavLink href="#features">Features</MobileNavLink>
//             <MobileNavLink href="#testimonials">Testimonials</MobileNavLink>
//             <MobileNavLink href="#pricing">Pricing</MobileNavLink>
//             <hr className="m-2 border-slate-300/40" />
//             <MobileNavLink href="/login">Sign in</MobileNavLink>
//           </Popover.Panel>
//         </Transition.Child>
//       </Transition.Root>
//     </Popover>
//   );
// };

export const Header: React.FC<{
  currentUser: string;
  setCurrentUser: React.Dispatch<React.SetStateAction<string>>;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ currentUser, setCurrentUser, setIsLoggedIn }) => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    setCurrentUser('');
    setIsLoggedIn(false);
    navigate(appLinks.LOGIN);
  };

  return (
    <header className="py-4 bg-gradient-to-r from-sky-700 to-sky-600">
      <div className="mx-auto px-3 lg:px-0 max-w-[1000px]">
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <WebLink to={appLinks.MAIN_PAGE}>
              <img
                src="icons/notes-icon-inverted.png"
                alt="icon"
                width={36}
                height={36}
              />
            </WebLink>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            <div className="flex">
              {/* <div className="hidden md:block"> */}
              <span className="mr-5 hidden sm:block text-white">
                {currentUser}
              </span>
              <WebSecondaryButton onClick={logout}>Log out</WebSecondaryButton>
            </div>
            {/* <div className="-mr-1 md:hidden">
                <MobileNavigation />
              </div> */}
          </div>
        </nav>
      </div>
    </header>
  );
};
